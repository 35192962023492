import React from 'react'
import Header from '../components/global/header/header'
import Hero from '../components/heros/general-content'
import FeaturedImage from '../components/general-content/featured-image'
import CallToAction from '../components/global/cta'
import Footer from '../components/global/footer/footer'
import PageTransition from 'gatsby-plugin-page-transitions'
import TextBlock from '../components/global/textblock/with-a-link'
import moment from 'moment'
import _ from 'lodash';
import { Link } from '../i18n'

// Images
import eventsExampleImage from '../images/news-example.jpg'

class GeneralContentPage extends React.Component{ 
  constructor(props) {
    super(props);
    this.state = {
      contactOpen: false,
    }
  }

  toggleContact = () => {
    this.setState({
      contactOpen: !this.state.contactOpen
    });
    document.body.classList.toggle('noscroll');
  }

  componentDidMount() {
    if(document.body.classList.contains('noscroll')) {
      document.body.classList.remove('noscroll');
    }
  }

  render(){
    const { article, section } = this.props
    if(!article) return null;

    const date = article.publishedDate 
      ? moment(article.publishedDate).format('Do MMM YYYY') 
      : '';

    const backLink = `/${section}`;

    return (
      <PageTransition
        defaultStyle={{
          transition: 'all 1100ms cubic-bezier(0.8, 0, 0.2, 1)',
          position: 'relative',
          width: '100%',
          opacity: 0,
          maxWidth: '2000px'
        }}

        transitionStyles={{
          entering: { opacity: 1 },
          entered: { opacity: 1 },
          exiting: { opacity: 0, }
        }}
        transitionTime={1250}
        >

        <Header
          headerStyle="gencont"
          contactOpen={this.state.contactOpen}
          action={() => this.toggleContact()}
          {...this.props }
        />

        <Hero
          date={date}
          pageTitle={article.title}
        />

        {article.image && article.image.secure_url && 
          <FeaturedImage
            image={article.image.secure_url}
          />
        }

        <section className="textblock bg-white">
          {/* Content */}
          <div className="row expanded">
            <div className="small-12 medium-push-1 medium-10 large-push-2 large-8 xlarge-push-3 xlarge-6 columns">
              <div className="textblock-content pad-bot-small pad-top-small textblock-intro-text" 
                dangerouslySetInnerHTML={{__html: article.content.brief}}>
              </div>
              <div className="textblock-content pad-bot-small textblock-main-text"
                dangerouslySetInnerHTML={{__html: article.content.extended}}>
              </div>
            </div>
          </div>
        </section>

        <section className="textblock bg-white">
          {/* Content */}
          <div className="row expanded collapse">
            <div className="small-12 columns">
              <hr/>
            </div>
            <div className="small-12 medium-push-1 medium-10 large-push-2 large-8 xxlarge-push-3 xxlarge-6 columns end">
              <div className="row expanded">
                <div className="small-12 columns pad-top-small pad-bot-small">
                  <Link to={backLink} className="button dark go-left">Back to {_.capitalize(section)} <span></span></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </PageTransition>  
    );
  }
} 

export default GeneralContentPage



// ./src/pages/general-content.jsx