import React from 'react'

const FeaturedImage = ({ image }) => (
    <section className="gencont-featimage">
        <div className="row expanded collapse pad-bot-large-only">
            <div className="small-12 medium-push-1 medium-10 large-push-2 large-8 columns">
                <div className="gencont-featimage__wrap">
                    <div>
                        <img src={image} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default FeaturedImage



