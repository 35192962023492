import React from 'react'

const HeroGeneralContent = ({ date, pageTitle}) => (
    <section className="gencont-hero pad-top pad-bot-small">
        <div className="row expanded">
            <div className="small-12 medium-10 medium-push-1 large-push-3 large-6 columns">
                <div className="herogencont-title">
                    { date && <em>{date}</em> }
                    <h1 className="dark">{pageTitle}</h1>
                </div>
            </div>
        </div>
    </section>
);

export default HeroGeneralContent



